import { graphql, navigate } from 'gatsby';
import { object, shape } from 'prop-types';
import React, { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';

import { Action, Paragraph, Separator, Subtitle, TextBlock, TextString, Title } from 'ui/components';
import { breakpoint } from 'ui/settings';
import { Fold, Helmet, Layout } from 'ui/partials';
import { setSpace, setType } from 'ui/mixins';

const Articles = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Article = styled.li`
  ${setSpace('pvm')};
  cursor: pointer;
  width: 100%;
  &:not(:last-child) {
    ${setSpace('mbx')};
  }
  ${Subtitle} {
    a {
      font-family: inherit;
    }
  }
  ${breakpoint.phone} {
    text-align: center;
  }
  ${breakpoint.tabletUp} {
    border-bottom: 1px solid ${({ theme }) => theme.decor};
  }
  ${breakpoint.desktopUp} {
    ${Subtitle} {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;
const ArticleDate = styled(TextString)`
  ${setType('x')};
  color: ${({ theme }) => theme.lowContrast};
  display: block;
  font-weight: normal;
  ${breakpoint.phone} {
    display: block;
  }
`;

const ArticlesListing = props => {
  const { theme } = props;
  const { edges: articles } = props.data.allMarkdownRemark;
  return (
    <Fragment>
      <Helmet {...props} title="Articles" />
      <Layout {...props}>
        <Fold>
          <TextBlock>
            <Title>
              <TextString looks="hcap">Articles</TextString>
              <TextString looks="h1">On business and tech</TextString>
            </Title>
            <Paragraph looks="p2">
              I don’t write as often as I wish, but when I do, I publish my pieces on this very page.
            </Paragraph>
          </TextBlock>
        </Fold>
        <Separator ornamental size="h" />
        <Articles>
          {articles.map(({ node: article }) => (
            <Article
              key={article.id}
              onClick={() => navigate(`/wrote/${article.frontmatter.uid}`)}
              role="link"
              theme={theme}>
              <Subtitle looks="h5">
                <Action plain to={`/wrote/${article.frontmatter.uid}`}>
                  {article.frontmatter.title}
                </Action>{' '}
                <ArticleDate theme={theme}>{article.frontmatter.date}</ArticleDate>
              </Subtitle>
            </Article>
          ))}
        </Articles>
      </Layout>
    </Fragment>
  );
};

export default withTheme(ArticlesListing);

ArticlesListing.propTypes = {
  data: shape({
    allMarkdownRemark: object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query AllArticlesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//pages/wrote/*/.*/*.md/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            uid
            date(formatString: "MMMM YYYY")
            title
          }
        }
      }
    }
  }
`;
